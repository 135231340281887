import logo from "../logo.png";
import appImage from "../iPhone5.png";
import "../App.css";
import {useState} from "react";

export const Career = () => {
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  return (
    <div className="App-container container">
      <div className={"app-row row"}>
        <div className={"AppContent  col-md-6"}>
          <div>
            <img src={logo} className="App-logo" alt="logo" />
            <h1>Career</h1>
            <p
              className="App-countdown"
              style={{
                lineHeight: "25px",
                width: "100%",
                height: "100%",
                padding: 30,
                display: "inherit",
              }}
            >
              Why MyPal:
              <br />
              <br />

              MyPal App enables mental health professionals to use their time{" "}
              <br />
              efficiently, work from comfort of their home, have more clients,{" "}
              <br />
              pay less commission, and thus generate more income. <br />
              <br />
              MyPal handles all record keepings, payments, Medicare and private
              <br />
              health insurance rebates which save your time and cost
              <br />
              significantly.
            </p>
          </div>
          <div              className="App-countdown"
                            style={{
                              width: "100%",
                              height: "45%",
                              padding: 30,
                              display: "grid",
                            }}>
              <p style={{width: '80%', margin: 'auto'}}>Join our mental health professionals’ team</p>
              <div style={{display: 'grid'}}>
              <input placeholder={'email'} className={"input"} style={{padding: 10, borderRadius: 5, border: '1px solid #ccc', width: 300, marginBottom: 10}} onChange={(event) => {
                setEmail(event.target.value)
              }}/>
              <input placeholder={'name'}  className={"input"} style={{padding: 10, borderRadius: 5, border: '1px solid #ccc', marginBottom: 10}} onChange={(event) => {
                setSubject(event.target.value)
              }}/>
              <textarea placeholder={'message'}  className={"input"} style={{padding: 10,borderRadius: 5, border: '1px solid #ccc', marginBottom: 10}} onChange={(event) => {
                setMessage(event.target.value)
              }}/>
              <button
                  style={{ borderRadius: 5, border: '1px solid #ccc', height: 30, marginBottom: 10}}
                  onClick={() => {
                    window.location.href = `mailto:career@mypalhealth.com.au?subject=${subject + ' ( ' + email + ' )'}&body=${message}`;
                  }}
              >
                Send
              </button>
            </div>
          </div>
        </div>
        <div className={"AppShow-Container  col-md-5"}>
          <img src={appImage} alt="imag" />
          <p style={{ color: "#fff" }}>
            Instant, Secure and Affordable
            <br /> healthcare services for all Australians.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Career;
