import logo from "../../logo.png";
import React from "react";
import agora from "../../agoralogo.png";
import google from "../../download.png";
import med from "../../Medicare.png";
export const Footer = () => {
  return (
    <div className="footer row">
      <div style={{display: 'flex', alignItems: 'center'}}>
        <img src={agora} style={{width: 100, height: 40}}/>
        <img src={google} style={{width: 100, height: 100}}/>
        <img src={med}  style={{width: 100, height: 40}}/>
          <img src={logo} className="App-logo" alt="logo"  style={{width: 40, height: 'auto'}}/>

      </div>
      <div>

        <h4>MYPAL HEALTH PTY LTD</h4>
        <p>Australian Company Number 653 315 176</p>
        <p style={{ color: "red" }}>
          MyPal is Not an emergency service, please call 000 if you are in a
          life-threatening situation.
        </p>
        <span>Fully Medicare integrated software.</span>
      </div>
    </div>
  );
};
