import React, {useState} from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const Header = (props) => {
  const [pathName, setPathName] = useState()
  const location = useLocation();
  const handleClick = (e) => {
    e.preventDefault();
    console.log("DO SOMETHING", e.target.pathname);
    setPathName(e.target.pathname)
    props.history.push(e.target.pathname);
  };
  return (
    <div className="header">
      <ul className="">
        <li>
          <Link style={{color: location.pathname == "/"? "#6667aa":"#3fb0d1"}} exact to="/">
            Home
          </Link>
        </li>
        <li>
          <Link style={{color: location.pathname == "/contact-us"? "#6667aa":"#3fb0d1"}} exact to="/contact-us">
            Contact
          </Link>
        </li>
        <li>
          <Link style={{color: location.pathname == "/about-us"? "#6667aa":"#3fb0d1"}} exact to="/about-us">
            About
          </Link>
        </li>
        <li>
          <Link style={{color: location.pathname == "/app"? "#6667aa":"#3fb0d1"}} exact to="/app">
            App
          </Link>
        </li>
        <li>
          <Link style={{color: location.pathname == "/career"? "#6667aa":"#3fb0d1"}} exact to="/career">
            Career
          </Link>
        </li>
      </ul>
    </div>
  );
};

export const HeaderNav = Header;
