import logo from "../logo.png";
import appImage from "../iPhone.png";
import "../App.css";
import { useReactCountdown } from "use-react-countdown";
import React from "react";
import { GooglePlayButton, AppStoreButton } from "react-mobile-app-button";

function Home() {

  let dateToEndCountdownAt = "October 09, 2023 00:00:00";
  const { days, hours, minutes, seconds } =
    useReactCountdown(dateToEndCountdownAt);
  return (
    <div className="App-container container">
      <div className={"app-row row"}>
        <div className={"AppContent col-md-6"}>
          <div>
            <img src={logo} className="App-logo" alt="logo" />
            <h1 style={{fontSize: 28}}>MyPal is the first Australian online therapy App.</h1>
            <h2>We're there for you</h2>
            <p
              className="App-countdown"
              style={{
                lineHeight: "25px",
                width: "100%",
                height: "100%",
                padding: 30,
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: '0px !important'
              }}
            >
              Mypal health is developing its own advanced proprietary
              <br />software to make healthcare services more accessible,
              <br />  secure and affordable for Australian people.
              <br /> Confidentiality is Mypal’s core value.
            </p>

            <div className="App-countdown-container row">
                <div style={{textAlign: 'center'}}>
                    <h3>Clinic App</h3>
                    <AppStoreButton
                        url={''}
                        theme={"dark"}
                        className={"custom-style"}
                        style={{height: 60}}
                    />
                    <GooglePlayButton
                        url={''}
                        theme={"dark"}
                        className={"custom-style"}
                    />
                </div>
                <div style={{textAlign: 'center'}}>
                    <h3>Mypal app</h3>
                    <AppStoreButton
                        url={''}
                        theme={"dark"}
                        className={"custom-style"}
                    />
                    <GooglePlayButton
                        url={''}
                        theme={"dark"}
                        className={"custom-style"}
                    />
                </div>
            </div>
          </div>
        </div>
        <div className={"AppShow-Container col-md-5"}>
          <img src={appImage} alt="mypal" />
          <p style={{ color: "#fff" }}>
            Instant, Secure and Affordable
            <br /> healthcare services for all Australians.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Home;
