import React from "react";
import logo from "../logo.png";
import appImage from "../iPhone4.png";
import map from "../map.png";
import "../App.css";
import { useReactCountdown } from "use-react-countdown";

export const AboutApp = () => {
  return (
    <div className="App-container container">
      <div
        className={"app-row row"}
        style={{ justifyContent: "space-evenly"}}
      >
        <div className={"AppShow-Container col-md-5"}>
          <img src={appImage} alt="ima-app" />
          <p style={{ color: "#fff" }}>
            Instant, Secure and Affordable
            <br /> healthcare services for all Australians.
          </p>
        </div>
        <div className={"AppContent col-md-6"}>
          <div style={{marginTop: 10}}>
            <p
              className="App-countdown appAbout"
              style={{
                lineHeight: "25px",
                width: "100%",
                height: "100%",
                padding: 30,
                display: "inherit",
              }}
            >
              MyPal mobile App brings instant, secure and affordable counselling
              <br />& psychological services to peoples’ fingertips 24/7.
              <br />
              <br />
              MyPal App provides a supreme privacy feature via end-to-end
              <br />
              encryption technology.
              <br />
              <br />
              For clients, MyPal App increases accessibility via:
              <ul>
                <li>
                  Eliminating time & location barriers by providing access to
                  <br />
                  therapists across the country 24/7
                </li>
                <li>Eliminating travel efforts, time & costs</li>
                <li>Reducing waiting time </li>
              </ul>
              <br />
              MyPal increases affordability by:
              <ul>
                <li>Charging 50% less commission</li>
                <li>Enabling Medicare and private health insurance rebates</li>
                <li>Charging 15 min blocks as opposed to hourly sessions</li>
              </ul>
              <br />
              For therapists, MyPal:
              <ul>
                <li>
                  Enables all the benefits of working from home including saving
                  <br />
                  on travel time & office cost which leads to less running cost
                  <br />
                  and increased availability
                </li>
                <li>
                  processes all payments and paperwork including Medicare and
                  <br />
                  private health insurance rebates
                </li>
                <li>
                  Increases profit margin through charging 50 % less commission
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutApp;
