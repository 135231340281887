import React from "react";
import "./App.css";
import { Router, Route, Routes, BrowserRouter } from "react-router-dom";
import { HeaderNav } from "./component/header";
import { Contact } from "./contact";
import { About } from "./about";
import { Footer } from "./component/footer";
import Home from "./home";
import AboutApp from "./about-app";
import Career from "./career";
import { createBrowserHistory } from "history";
function App() {
  const history = createBrowserHistory();
  return (
    <div className="App">
      <BrowserRouter history={history}>
        <HeaderNav />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/app" element={<AboutApp />} />
          <Route path="/career" element={<Career />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
