import logo from "../logo.png";
import appImage from "../iPhone2.png";
import map from "../map.png";
import "../App.css";

export const Contact = () => {
  return (
    <div className="App-container container">
      <div className={"app-row row"}>
        <div className={"AppShow-Container col-md-5"}>
          <img src={appImage} alt="ima-app" />
          <p style={{ color: "#fff" }}>
            Instant, Secure and Affordable
            <br /> healthcare services for all Australians.
          </p>
        </div>
        <div className={"AppContent col-md-6"} style={{ width: "57%" }}>
          <div style={{ marginTop: 85 }}>
            <div className="App-contact-container">
              <img src={map} className="Icon" alt="logo" />
              <p>info@mypalhealth.com.au</p>
            </div>
            <div className="App-contact-container">
              <img src={map} className="Icon" alt="logo" />
              <p>506/9 Winning Street, Kellyville NSW, 2155</p>
            </div>
            <p style={{ marginTop: 38 }}>
              MYPAL HEALTH PTY LTD
              <br /> Australian Company Number 653 315 176
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
