import logo from "../logo.png";
import appImage from "../iPhone3.png";
import "../App.css";
import { useReactCountdown } from "use-react-countdown";

export const About = () => {
  let dateToEndCountdownAt = "July 22, 2023 00:00:00";
  const { days, hours, minutes, seconds } =
    useReactCountdown(dateToEndCountdownAt);
  return (
    <div className="App-container container">
      <div className={"app-row row"}>
        <div className={"AppContent col-md-6"}>
          <div>
            <img src={logo} className="App-logo" alt="logo" />
            <h1>Our Mission</h1>
            <p
              className="App-countdown"
              style={{
                lineHeight: "25px",
                width: "100%",
                height: "100%",
                padding: 30,
              }}
            >
              Empowering people to cherish their health by providing secure,
              <br />
              convenient and affordable healthcare services. Confidentiality is
              <br />a MyPal’s core value.
            </p>
            <h1>About us</h1>
            <p
              className="App-countdown"
              style={{
                lineHeight: "25px",
                width: "100%",
                height: "100%",
                padding: 30,
              }}
            >
              MyPal App is an online platform to
              <br />
              facilitate secure counselling & psychological services via text
              <br />
              messaging, voice calls and video calls. We are developing a<br />
              proprietary software that is fully integrated in Australian
              <br />
              healthcare system which enables seamless Medicare and private
              <br />
              health insurance rebates.
            </p>
          </div>
        </div>
        <div className={"AppShow-Container col-md-5"}>
          <img src={appImage} alt="imag" />
          <p style={{ color: "#fff" }}>
            Instant, Secure and Affordable
            <br /> healthcare services for all Australians.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
